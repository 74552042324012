'use client';

import { useRouter } from 'next/navigation';
import { useRef } from 'react';
import { Button } from '@kit/ui/button';

export default function TweetInput() {
    const inputRef = useRef<HTMLInputElement>(null);
    const router = useRouter();

    return (
        <div className="flex justify-center items-center gap-2 w-full">
            <div className="flex flex-col gap-2 w-full max-w-3xl mr-10">
                <label>Tweet URL</label>
                <input
                    ref={inputRef}
                    type="text"
                    className="input text-foreground bg-background border placeholder:italic"
                    placeholder="https://twitter.com/jack/status/1715066173223608600"
                />
            </div>
            <Button
                className="mt-7"
                onClick={() => {
                    if (inputRef.current) {
                        const tweetId = inputRef.current.value.split('/').pop();
                        if (tweetId) {
                            router.push(`/tools/tweet-screenshot/${tweetId}`);
                        }
                    }
                }}
            >
                Get Tweet
            </Button>
        </div>
    );
}
